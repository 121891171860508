import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Typography,
  IconButton,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import { Bid } from "../BidCard";
import { ProductType } from "../product.type";
import CurrencyPrice from "../../common/PriceWithCurrency";
import ConfirmDialog from "../ConfirmDialog";

interface User {
  uuid: string;
}

interface BidsModalProps {
  bids: Bid[];
  product: ProductType;
  user: User;
  open: boolean;
  currency: string;
  handleClose: () => void;
  handleAccepterChange: (bid: Bid) => void;
  handleBidDelete: (bid: Bid) => void;
}

const ViewBidModal: React.FC<BidsModalProps> = ({
  bids,
  product,
  user,
  open,
  currency,
  handleClose,
  handleAccepterChange,
  handleBidDelete,
}) => {
  const [deleteDeleteDialog, setDeleteDeleteDialog] = useState(false);
  const [selectedBid, setSelectedBid] = useState<Bid | null>(null);
  const handleDeleteConfirm = () => {
    if (selectedBid) {
      handleBidDelete(selectedBid);
      setDeleteDeleteDialog(false);
    }
  };

  const handleDeleteDialog = (bid: Bid) => {
    setDeleteDeleteDialog(true);
    setSelectedBid(bid);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>Bids</DialogTitle>
        <DialogContent>
          {bids && bids.length > 0 ? (
            <>
              {/* Table-like Header using Grid */}
              <Grid
                container
               
                sx={{
                  backgroundColor: "#1A4847",
                  width:"100%",
                  color: "white",
                  padding: "2px",
                  marginBottom: "1rem",
                }}
              >
                <Grid item xs={4} textAlign={"center"}>
                  <Typography variant="subtitle1" sx={{ color: "white" }}>
                    Bidder
                  </Typography>
                </Grid>
                <Grid item xs={5} textAlign={"center"}>
                  <Typography variant="subtitle1" sx={{ color: "white" }}>
                    Bid Amount
                  </Typography>
                </Grid>
                {product?.seller?.id === user?.uuid && (
                  <Grid item xs={2} textAlign={"start"}>
                    <Typography variant="subtitle1" sx={{ color: "white" }}>
                      Actions
                    </Typography>
                  </Grid>
                )}
              </Grid>

              {/* Grid Rows for Each Bid */}
              {bids.map((bid, index) => (
                <Grid
                  container
                  spacing={2}
                  key={index}
                  sx={{ alignItems: "center", marginBottom: "0.5rem" }}
                >
                  <Grid item xs={5}>
                    <Typography variant="body1" sx={{ color: "black" }}>
                      {bid?.buyer?.first_name} {bid?.buyer?.last_name}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} textAlign={"center"}>
                    <Typography variant="body1" sx={{ color: "gray" }}>
                      <CurrencyPrice
                        price={bid?.amount}
                        currency={bid?.currency || currency}
                      />
                    </Typography>
                  </Grid>

                  <Grid item xs={3}>
                    {bid?.buyer?.id === user?.uuid && (
                      <IconButton
                        color="primary"
                        onClick={() => handleDeleteDialog(bid)}
                        title="Delete bid"
                        sx={{ marginRight: "5px" }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}

                    {product?.seller?.id === user?.uuid && (
                      <IconButton
                     
                      
                        color="primary"
                        onClick={() => handleAccepterChange(bid)}
                        title="Accept bid"
                      >
                        <CheckIcon />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              ))}
            </>
          ) : (
            <Typography>No bids available</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmDialog
        open={deleteDeleteDialog}
        title="Confirm Delete Bid"
        description={`Are you sure you want to delete this bid?`}
        onCancel={() => setDeleteDeleteDialog(false)}
        onConfirm={() => handleDeleteConfirm()}
      />
    </>
  );
};

export default ViewBidModal;
