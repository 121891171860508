import React, { useState } from "react";
import "./ProductListcss.css";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import noImage from "../../assets/Noimage.jpg";
import { Box } from "@mui/material";
import { getMediaType } from "../utils/helpers";
import { useTheme } from "@mui/material/styles";
import {
  useMediaQuery,
} from "@mui/material";

const Carousel = ({ images = [], isSold = false }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const data = images?.length
    ? images.map((image: string, index: number) => {
        return {
          src: image,
          alt: `Image ${index}`,
          type: getMediaType(image),
        };
      })
    : [
        {
          src: noImage,
          alt: "NO Image",
          type: "image",
        },
      ];

  const [slide, setSlide] = useState(0);

  const nextSlide = () => {
    setSlide(slide === data.length - 1 ? 0 : slide + 1);
  };

  const prevSlide = () => {
    setSlide(slide === 0 ? data.length - 1 : slide - 1);
  };

  const [carousel, setStyle] = useState("carousel");

  const changeStyle = () => {
    setStyle(carousel !== "carousel" ? "carousel" : isSmallScreen? "carousel": "carouselhover");
  };

  // State for the indicator slide index
  const [indicatorSlide, setIndicatorSlide] = useState(0);

  const indicatorsPerPage = 6; // Updated to handle 6 indicators per page

  const maxIndicatorSlide = Math.floor((data.length - 1) / indicatorsPerPage);

  const nextIndicatorSlide = () => {
    setIndicatorSlide(
      indicatorSlide === maxIndicatorSlide ? 0 : indicatorSlide + 1
    );
  };

  const prevIndicatorSlide = () => {
    setIndicatorSlide(
      indicatorSlide === 0 ? maxIndicatorSlide : indicatorSlide - 1
    );
  };
  console.log("isSold", isSold);
  return (
    <div className="slides">
      <div className={carousel}>
        {data.length < 2 ? (
          ""
        ) : (
          <KeyboardArrowLeftIcon
            onClick={prevSlide}
            className="arrow arrow-left"
          />
        )}
        {isSold && (
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%) rotate(-45deg)", // Center and rotate
              backgroundColor: "rgba(92, 234, 210, 1)", // Red semi-transparent background
              color: "white",
              fontSize: "24px",
              fontWeight: "bold",
              padding: "10px 30px",
              borderRadius: "5px",
              zIndex: 2, // Ensure it's on top of the image
              textAlign: "center",
            }}
          >
            SOLD
          </Box>
        )}
        {data.map((item: any, idx: number) => {
          return (
            <>
              {item.type === "image" && (
                <img
                  src={item.src}
                  alt={item.alt}
                  key={idx}
                  className={slide === idx ? "slide" : "slide slide-hidden"}
                  onClick={changeStyle}
                  style={{ maxHeight: "415px" }}
                />
              )}
              {item.type === "video" && (
                <video
                  key={idx}
                  autoPlay
                  muted
                  loop
                  controls
                  className={slide === idx ? "slide" : "slide slide-hidden"}
                  style={{
                    objectFit: "cover",
                    minWidth: "100%",
                    minHeight: "100%",
                    maxHeight: "415px",
                  }}
                  onClick={changeStyle}
                >
                  <source src={item.src} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              )}
            </>
          );
        })}

        {data.length < 2 ? (
          ""
        ) : (
          <KeyboardArrowRightIcon
            onClick={nextSlide}
            className="arrow arrow-right"
          />
        )}
      </div>

      {data.length > 1 && (
        <div className="indicators-container">
          <div className="indicators">
            <div className="indicator-arrow-indicator-arrow-left">
              {data.length > indicatorsPerPage && (
                <KeyboardArrowLeftIcon onClick={prevIndicatorSlide} />
              )}
            </div>
            {data
              .slice(
                indicatorSlide * indicatorsPerPage,
                indicatorSlide * indicatorsPerPage + indicatorsPerPage
              )
              .map((item: any, idx: number) => {
                return (
                  <>
                    {item.type === "image" && (
                      <img
                        src={item.src}
                        alt={item.alt}
                        key={indicatorSlide * indicatorsPerPage + idx}
                        className={
                          slide === indicatorSlide * indicatorsPerPage + idx
                            ? "indicator"
                            : "indicator indicator-inactive"
                        }
                        onClick={() =>
                          setSlide(indicatorSlide * indicatorsPerPage + idx)
                        }
                      />
                    )}
                    {item.type === "video" && (
                      <video
                        autoPlay={false}
                        muted
                        loop
                        controls={false}
                        style={{
                          objectFit: "cover",
                        }}
                        className={
                          slide === indicatorSlide * indicatorsPerPage + idx
                            ? "indicator"
                            : "indicator indicator-inactive"
                        }
                        onClick={() =>
                          setSlide(indicatorSlide * indicatorsPerPage + idx)
                        }
                      >
                        <source src={item.src} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    )}{" "}
                  </>
                );
              })}
            <div className="indicator-arrow-indicator-arrow-right">
              {data.length > indicatorsPerPage && (
                <KeyboardArrowRightIcon onClick={nextIndicatorSlide} />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Carousel;
