import { createSlice, createAsyncThunk, PayloadAction, createSelector } from "@reduxjs/toolkit";
import {
  ICategory,
  ISubCategory,
} from "../../components/category/category.type";
import fetchWrapper from "../../components/utils/fetchWrapper";
import { RootState } from "../store";

interface CategoryState {
  loading: boolean;
  category: ICategory[];
  categoryFilterOptions: {
    categories: [];
    subCategories: ISubCategory[];
  };
  error: string | null;
}

const initialState: CategoryState = {
  loading: false,
  category: [],
  categoryFilterOptions: {
    categories: [],
    subCategories: [],
  },
  error: null,
};

export const fetchCategory = createAsyncThunk(
  "category/fetchCategory",
  async () => {
    const categories = await fetchWrapper("categories");
    return categories.data as ICategory[];
  }
);

const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: { },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategory.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchCategory.fulfilled,
        (state, action: PayloadAction<ICategory[]>) => {
          state.loading = false;
          state.category = action.payload;
        }
      )
      .addCase(fetchCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
      });
  },
});

// Simple selector to get the categories slice
const selectCategoryState = (state: RootState) => state.category;

export const getCategoriesSelector = createSelector(
  [selectCategoryState],
  (categoryState) => categoryState.category.map((category: ICategory) => ({
    ...category,
    value: category.uuid,
    label: category.displayName,
  }))
);

export const getSubCategoriesSelector = createSelector(
  [selectCategoryState, (_: RootState, selectedCategoryId: string) => selectedCategoryId],
  (categoryState, selectedCategoryId) => {
    const getSubCategory = categoryState.category.find(
      (categoryItem: ICategory) => categoryItem.uuid === selectedCategoryId
    );
    return (
      getSubCategory?.subcategories.map((subcategory: ISubCategory) => ({
        ...subcategory,
        value: subcategory.uuid,
        label: subcategory.displayName,
      })) || []
    );
  }
);

export const getCategoryTags = (state: CategoryState) => {
  return state.category.filter((item) => item.tags.includes("popular"));
};

export default categorySlice.reducer;
