import * as React from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";

interface BasicDatePickerProps {
  label: string;
  onChange: (date: Date) => void;
  value: Dayjs | null;
  name: string;
}

export default function BasicDatePicker({
  name,
  label,
  onChange,
  value,
}: BasicDatePickerProps) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["DatePicker"]}>
        <DatePicker
          name={name}
          label={label}
          value={value}
          minDate={dayjs()}
          onChange={(newValue) => newValue && onChange(newValue.toDate())}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}
