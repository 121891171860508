import {
  ListSubheader,
  Grid,
  SelectChangeEvent,
  TextField,
  Button,
  Box,
  colors,
  useMediaQuery,
} from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";

import SelectDropdown from "../../../components/common/SelectDropdown";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import { useTheme } from "@mui/material/styles";
import {
  getCategoriesSelector,
  getSubCategoriesSelector,
} from "../../../store/slices/category.slice";
import { setFilters, clearFilters } from "../../../store/slices/filter.slice";
import { fetchProducts } from "../../../store/slices/product.slice";
import { useDispatch } from "react-redux";
//import DropdownWithPriceRange from "../../../components/common/DropdownWithPriceRange";
import { updateNestedValue } from "../../../components/utils/helpers";
import { DEAL_CATEGORY } from "./FilterOptions";
import GeosuggestDropdown from "../../../components/GeosuggestDropdown";

type SubFiltersProps = {};

const SubProductFilters: React.FC<SubFiltersProps> = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isBigScreen = useMediaQuery(theme.breakpoints.up("md"));
  const dispatch = useDispatch<AppDispatch>();
  const { filters: selectedFilters } = useSelector(
    (state: RootState) => state.filter
  );
  const categories = useSelector((state: RootState) =>
    getCategoriesSelector(state)
  );

  const location = useSelector((state: RootState) => state.location);
  const subCategories = useSelector((state: RootState) =>
    getSubCategoriesSelector(state, selectedFilters.category.uuid)
  );
  const [price, setPrice] = useState<{ min: number; max: number }>({
    min: 0,
    max: 0,
  });

  const handleOnChange = (event: SelectChangeEvent<string>) => {
    const { value, name } = event.target;
    let updatedValues;
    if (name === "category.uuid") {
      const updateCategory = updateNestedValue(
        { ...selectedFilters },
        name,
        value
      );
      updatedValues = updateNestedValue(
        { ...updateCategory },
        "subcategory.uuid",
        ""
      );
    } else {
      updatedValues = updateNestedValue({ ...selectedFilters }, name, value);
    }
    dispatch(setFilters(updatedValues));
  };

  useEffect(() => {
    dispatch(
      setFilters({
        ...selectedFilters,
        location: location?.location,
      })
    );
    dispatch(fetchProducts());
  }, [location?.location]);

  const handleInputOnChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value, name } = event.target;
    let updatedValues;
    if (name === "category.uuid") {
      updatedValues = updateNestedValue(
        { ...selectedFilters, "subcategory.uuid": "" },
        name,
        value
      );
    } else {
      updatedValues = updateNestedValue({ ...selectedFilters }, name, value);
    }
    dispatch(setFilters(updatedValues));
  };

  const onSearchClick = () => {
    dispatch(fetchProducts());
  };

  const onClearSearchClick = () => {
    dispatch(clearFilters());
    dispatch(fetchProducts());
    setPrice({ min: 0, max: 0 });
  };

  const handleLocationChange = (location: any = {}) => {
    const country = updateNestedValue(
      { ...selectedFilters },
      "location.country",
      location.country
    );
    const state = updateNestedValue(
      { ...country },
      "location.state",
      location.state
    );
    const updatedValues = updateNestedValue(
      { ...state },
      "location.city",
      location.city
    );
    dispatch(setFilters(updatedValues));
  };

  return (
    <ListSubheader
      sx={{
        mt: isSmallScreen ? 2 : 7,
        bgcolor: isSmallScreen ? "whitesmoke" : "#1A4847",
        padding: "5px",
        borderRadius: "50px",
      }}
    >
      <Grid
        container
        columns={16}
        sx={{ width: isSmallScreen ? "100%" : "110%" }}
      >
        <Grid item>
          <TextField
            id="outlined-basic"
            placeholder="Search anything..."
            variant="outlined"
            size="small"
            name="search"
            value={selectedFilters.search}
            onChange={handleInputOnChange}
            sx={{
              m: 1,
              background: "white",
              width: isSmallScreen ? "22rem" : isBigScreen ? "22rem" : "6rem",
              borderRadius: "20px",
              outline: "0px",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderRadius: "20px",
                },
                "& input::placeholder": {
                  color: "black",
                  opacity: 0.8,
                },
              },
            }}
          />
        </Grid>
        <Grid item>
          <Box
            sx={{
              m: 1,
              ml: 0,
              width: isSmallScreen ? "4rem" : isBigScreen ? "11rem" : "4rem",
            }}
          >
            <GeosuggestDropdown
              showInput={true}
              setShowInput={() => {}}
              onChange={handleLocationChange}
              hideDelete={true}
              filterLocation
              value={[
                selectedFilters.location?.city,
                selectedFilters.location?.state,
                selectedFilters.location?.country,
              ]
                .filter(Boolean)
                .join(", ")}
            />
          </Box>
        </Grid>
        <Grid item>
          <SelectDropdown
            label="Category"
            name="category.uuid"
            value={selectedFilters.category.uuid}
            options={categories}
            onChange={handleOnChange}
            sxOptions={{
              m: 1,
              ml: 2,
              width: isBigScreen ? "10rem" : "6.6rem",
              backgroundColor: "white",
              borderRadius: "20px",
              outline: "0px",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  colors: "blue",

                  borderRadius: "20px",
                },
              },
            }}
          />
        </Grid>
        <Grid item>
          <SelectDropdown
            label="Sub category"
            name="subcategory.uuid"
            value={selectedFilters.subcategory.uuid}
            options={subCategories}
            onChange={handleOnChange}
            sxOptions={{
              m: 1,
              ml: 0,
              width: isBigScreen ? "10rem" : "8.5rem",
              backgroundColor: "white",
              borderRadius: "20px",
              outline: "0px",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  colors: "white",
                  borderRadius: "20px",
                },
              },
            }}
          />
        </Grid>
        {/* <Grid>
          <DropdownWithPriceRange price={price} setPrice={setPrice} />
        </Grid> */}
        <Grid item>
          <SelectDropdown
            label="Tags"
            name="tags"
            value={selectedFilters.tags}
            options={DEAL_CATEGORY}
            onChange={handleOnChange}
            sxOptions={{
              m: 1,
              ml: 0,
              width: isBigScreen ? "7rem" : "4.5rem",
              backgroundColor: "white",
              borderRadius: "20px",
              outline: "0px",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  colors: "white",
                  borderRadius: "20px",
                },
              },
            }}
          />
        </Grid>
        <Grid>
          <Button
            variant="contained"
            onClick={onSearchClick}
            sx={{
              m: 1,
              ml: 0,
              background: "#e16330",
              borderRadius: "20px",
              fontSize: "1rem",
            }}
          >
            Search
          </Button>
          <Button
            variant="outlined"
            onClick={onClearSearchClick}
            sx={{
              m: 1,
              ml: 0,
              pl: 0,
              borderRadius: "20px",
              color: isSmallScreen ? "black" : "white",
              fontSize: "1rem",
              border: "none",
              ":hover": {
                border: "none",
                color: "orange",
              },
            }}
          >
            Clear
          </Button>
        </Grid>
      </Grid>

      {/* <Grid
        container
        spacing={{ xs: 2.5, md: 2.5 }}
        columns={{ xs: 3, sm: 4, md: 12 }}
        columnGap={1}
      >
        <TextField
          id="outlined-basic"
          label="Search"
          variant="outlined"
          size="small"
          name="search"
          value={selectedFilters.search}
          onChange={handleInputOnChange}
          sx={{ m: 1 }}
        />

        <SelectDropdown
          label="Category"
          name="category.uuid"
          value={selectedFilters.category.uuid}
          options={categories}
          onChange={handleOnChange}
          sxOptions={{ m: 1, minWidth: 180 }}
        />

        <SelectDropdown
          label="Sub category"
          name="subcategory.uuid"
          value={selectedFilters.subcategory.uuid}
          options={subCategories}
          onChange={handleOnChange}
          sxOptions={{ m: 1, minWidth: 180 }}
        />

        <DropdownWithPriceRange />
      </Grid>
      <Button variant="contained" onClick={onSearchClick}>
        Search
      </Button> */}
    </ListSubheader>
  );
};

export default SubProductFilters;
