export const PROPERTY_TYPE = [
  { id: 1, name: "Apartment", label: "Apartment" },
  { id: 2, name: "Builder Floors", label: "Builder Floors" },
  { id: 3, name: "Farm House", label: "Farm House" },
  { id: 4, name: "Houses & Villas", label: "Houses & Villas" },
];

export const BEDROOMS_BATHROOMS = [
  { id: 1, name: "1", label: "1" },
  { id: 2, name: "2", label: "2" },
  { id: 3, name: "3", label: "3" },
  { id: 4, name: "4", label: "4" },
  { id: 5, name: "4+", label: "4+" },
];

export const FURNISHING_TYPE = [
  { id: 1, name: "Furnished", label: "Furnished" },
  { id: 2, name: "Semi Furnished", label: "Semi Furnished" },
  { id: 3, name: "Unfurnished", label: "Unfurnished" },
];

export const BEDS = [
  { label: "Studio", value: "studio" },
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
];

export const BATHROOMS = [
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
];

export const COUNTRY_CURRENCY = {
  Australia: "AUD", // Australian Dollar
  Bulgaria: "BGN", // Bulgarian Lev
  Brazil: "BRL", // Brazilian Real
  Canada: "CAD", // Canadian Dollar
  Switzerland: "CHF", // Swiss Franc
  China: "CNY", // Chinese Yuan
  "Czech Republic": "CZK", // Czech Koruna
  Denmark: "DKK", // Danish Krone
  Eurozone: "EUR", // Euro
  "United Kingdom": "GBP", // British Pound Sterling
  "Hong Kong": "HKD", // Hong Kong Dollar
  Croatia: "HRK", // Croatian Kuna
  Hungary: "HUF", // Hungarian Forint
  Indonesia: "IDR", // Indonesian Rupiah
  Israel: "ILS", // Israeli New Shekel
  India: "INR", // Indian Rupee
  Iceland: "ISK", // Icelandic Króna
  Japan: "JPY", // Japanese Yen
  "South Korea": "KRW", // South Korean Won
  Mexico: "MXN", // Mexican Peso
  Malaysia: "MYR", // Malaysian Ringgit
  Norway: "NOK", // Norwegian Krone
  "New Zealand": "NZD", // New Zealand Dollar
  Philippines: "PHP", // Philippine Peso
  Poland: "PLN", // Polish Złoty
  Romania: "RON", // Romanian Leu
  Russia: "RUB", // Russian Ruble
  Sweden: "SEK", // Swedish Krona
  Singapore: "SGD", // Singapore Dollar
  Thailand: "THB", // Thai Baht
  Turkey: "TRY", // Turkish Lira
  "United States": "USD", // US Dollar
  "South Africa": "ZAR", // South African Rand
  "United Arab Emirates": "AED",
  Iraq: "IQD",
  Oman: "OMR",
};

export const currencySymbols: Record<string, string> = {
  INR: "₹",
  USD: "$",
  EUR: "€",
  AED: "د.إ",
  OMR: "﷼",
};

export const FILE_CONFIG = {
  maxFileSize: 10 * 1024 * 1024, // 10 MB
  maxVideoSize: 60 * 1024 * 1024, // 60 MB
};
