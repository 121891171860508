import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Container,
  Grid,
  Box,
  Divider,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
// import { orange } from "@mui/material/colors";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SingleBedIcon from "@mui/icons-material/SingleBed";
import BathtubIcon from "@mui/icons-material/Bathtub";
import DirectionsCarFilledIcon from "@mui/icons-material/DirectionsCarFilled";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import {
  fetchSingleProductData,
  filterLocation,
} from "../services/product.service";
import Carousel from "./Carousel";
import BidCard from "./BidCard";
import Breadcrumb from "../BreadCrumbs";
import "./ProductListcss.css";
import { Flag, Loyalty } from "@mui/icons-material";
import SimilarProducts from "./SimilarProducts";
import PriceInsightsTable from "./PriceInsightsTable";
import ReportDialog from "./Modal/ReportDialog";
import { useFetchBids } from "../services/bid.service";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CurrencyPrice from "../common/PriceWithCurrency";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import ExclusiveDialog from "./Modal/ExclusiveDialog";
import { updateExclusiveProduct } from "../../store/slices/product.slice";
import profileAvtar from "../../assets/profile-avatar.png"

import { GoogleMap, MarkerF } from "@react-google-maps/api";

const mapContainerStyle = {
  width: "100%",
  height: "400px",
};

export default function ProductDetails() {
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch<AppDispatch>();

  const [openReport, setOpenReport] = useState(false);
  const [openExclusive, setOpenExclusive] = useState(false);

  const params = useParams();
  const { currentUserDetails } = useSelector((state: RootState) => state.auth);

  const {
    data: { product: data, similarProducts, priceInsightProducts } = {
      product: {},
      similarProducts: [],
      priceInsightProducts: [],
    },
    error,
    isLoading,
  } = useQuery(
    ["products", params.id],
    () => fetchSingleProductData(`${params.id}`, true),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: bids } = useFetchBids(params.id || "");

  const detailEntries: [string, string][] =
    data && data?.specifications ? Object.entries(data?.specifications) : [];

  if (isLoading) return <></>;
  if (error) return <div>Error loading product details.</div>;

  const sellerData = data?.seller?.id;
  const isExclusive = data?.tags.length > 0 && data?.tags.includes("EXCLUSIVE");
  const isAdmin = currentUserDetails?.user?.role === "ADMIN" || false;

  const handleExclusive = () => {
    setOpenExclusive(false);
    dispatch(
      updateExclusiveProduct({ uuid: data.uuid, isExclusive: !isExclusive })
    );
  };
  return (
    <Container sx={{ background: "white" }}>
      <Box
        position="static"
        sx={{
          display: "flex",
          // alignItems: "center",
          justifyContent: "space-between",
          padding: theme.spacing(2),
          mt: 5,
          fontSize: "10px",
        }}
      >
        <Grid container>
          <Grid item xs={8} sm={6} md={8}>
            <Breadcrumb title={data?.title} />
          </Grid>
          <Grid item xs={4} sm={6} md={2} sx={{ textAlign: "right" }}>
            <Button
              variant="outlined"
              onClick={() => setOpenReport(true)}
              startIcon={<Flag />}
            >
              Report
            </Button>
          </Grid>
          <Grid item xs={4} sm={6} md={2} sx={{ textAlign: "right" }}>
            {isAdmin && (
              <Button
                title="Make this product as exclusive"
                variant="contained"
                onClick={() => setOpenExclusive(true)}
                startIcon={<Loyalty />}
              >
                Exclusive
              </Button>
            )}
          </Grid>

         
        </Grid>
      </Box>

      <Grid container>
        <Grid item xs={12} sm={6} md={8}>
          <Carousel
            isSold={data?.state === "SOLD"}
            images={data?.images || []}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography
            gutterBottom
            variant="h2"
            sx={{ fontSize: "1.5rem", fontWeight: "bold" }}
          >
            {data?.title}
          </Typography>
          <Typography gutterBottom variant="h2" sx={{ fontSize: "1rem" }}>
            {data?.description?.slice(0, 200)}...
          </Typography>

          {data && data?.bidEndDate && (
            <BidCard product={data} bids={bids?.data} />
          )}
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} sm={6} md={8}>
          <Typography
            gutterBottom
            variant="h6"
            sx={{ fontSize: "1.5rem", marginTop: "7px" }}
          >
            <CurrencyPrice
              price={data?.price}
              currency={
                data?.currency || localStorage.getItem("currency") || "USD"
              }
            />
          </Typography>
          {data?.category?.name == "Properties" && (
            <Grid display={"flex"}>
              <SingleBedIcon sx={{ width: "1.5rem" }} />
              <Typography
                gutterBottom
                variant="h6"
                sx={{
                  fontSize: "1rem",
                  marginLeft: "6px",
                }}
              >
                {data?.specifications?.bedrooms} beds
              </Typography>
              <BathtubIcon
                sx={{
                  width: "1.5rem",
                  marginLeft: "20px",
                }}
              />
              <Typography
                gutterBottom
                variant="h6"
                sx={{
                  fontSize: "1rem",
                  marginLeft: "6px",
                }}
              >
                {data?.specifications?.bathrooms} baths
              </Typography>
              <DirectionsCarFilledIcon
                sx={{
                  width: "1.5rem",
                  marginLeft: "20px",
                }}
              />
              <Typography
                gutterBottom
                variant="h6"
                sx={{
                  fontSize: "1rem",
                  marginLeft: "6px",
                }}
              >
                {data?.specifications?.carParking} Car Parking
              </Typography>
            </Grid>
          )}

          <Typography
            gutterBottom
            variant="h6"
            sx={{
              fontSize: "1rem",
              borderBottom: "1px solid #d3d3d3",
              mr: "1rem",
            }}
          >
            <LocationOnIcon sx={{ width: "1rem" }} />{" "}
            {filterLocation(data?.location)}
          </Typography>
          {data?.address?.lat && data?.address?.lng && (
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              center={{ lat: +data?.address?.lat, lng: +data?.address?.lng }}
              zoom={15}
            >
              <MarkerF
                position={{ lat: +data.address.lat, lng: +data.address.lng }}
                label={`BidzBay - ${data?.title}`}
              />
            </GoogleMap>
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card
            sx={{
              width: "100%",
              background: "white",
              marginTop: "1.5rem",
              boxShadow: "0.8px 1px 3px 1px grey",
            }}
          >
            <CardContent>
              <Grid container alignItems="center">
                <Grid item xs={3} sm={3} md={3}>
                  <img
                    src={profileAvtar}
                    alt="Profile"
                    width={70}
                  />
                </Grid>
                <Grid item xs={9} sm={9} md={9}>
                  <Grid display={"flex"}>
                    <Grid item xs={4} sm={4} md={6}>
                      <Typography
                        gutterBottom
                        variant="h6"
                        sx={{ fontSize: "1rem", marginLeft: "10px" }}
                      >
                        {/* {data?.category?.name === "Properties"
                          ? data?.specifications?.listedBy
                          : "Seller"} */}
                        Seller
                      </Typography>
                    </Grid>
                    <Grid display={"flex"} item xs={8} sm={9} md={6}>
                      <Typography
                        gutterBottom
                        variant="h6"
                        sx={{
                          fontSize: "1rem",
                          color: "blue",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          navigate("/sellerprofile", { state: { sellerData } })
                        }
                      >
                        View Profile
                      </Typography>

                      <NavigateNextIcon
                        sx={{ color: "blue", cursor: "pointer" }}
                        onClick={() =>
                          navigate("/sellerprofile", { state: { sellerData } })
                        }
                      />
                    </Grid>
                  </Grid>
                  <Typography
                    gutterBottom
                    variant="h6"
                    style={{ textTransform: "capitalize" }}
                    sx={{
                      fontSize: "1.2rem",
                      marginLeft: "10px",
                      color: "black",
                    }}
                  >
                    {data?.seller?.first_name} {data?.seller?.last_name}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Box sx={{ maxWidth: "100%", margin: "auto" }}>
        <Typography
          variant="h5"
          sx={{
            fontWeight: 400,
            marginBottom: 1,
            marginTop: "10px",
            color: "primary.main",
          }}
        >
          {data?.category?.name === "Properties"
            ? "Property Details"
            : " Product Details"}
        </Typography>

        <Divider sx={{ marginBottom: 4 }} />

        <Grid container spacing={2}>
          {detailEntries.length ? (
            detailEntries.map(([key, value], index) =>
              value ? (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  key={key}
                  sx={{
                    borderRight: index % 3 !== 2 ? "1px solid #ddd" : "1px solid #ddd",
                    paddingRight: index % 2 !== 2 ? 2 : 0,
                   
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 1,
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{ textTransform: "capitalize" }}
                    >
                      {`${key.replace(/([A-Z])/g, " $1").trim()}:`}
                    </Typography>
                    <Typography variant="body2" sx={{ fontWeight: 600 }}>
                      {value}
                    </Typography>
                  </Box>
                </Grid>
              ) : null
            )
          ) : (
            <Box>
              <Typography variant="body2" sx={{ fontWeight: 600 }}>
                No details found
              </Typography>
            </Box>
          )}
        </Grid>
      </Box>
      <Divider sx={{ marginTop: 2 }} />
      <Typography
        variant="h5"
        sx={{ fontWeight: 400, marginBottom: 1, color: "primary.main" }}
      >
        More Information
      </Typography>
      <Typography gutterBottom variant="h2" sx={{ fontSize: "1rem" }}>
        {data?.description}
      </Typography>
      {priceInsightProducts?.length ? (
        <PriceInsightsTable priceInsightProducts={priceInsightProducts} />
      ) : null}
      {similarProducts?.length ? (
        <SimilarProducts similarProducts={similarProducts} />
      ) : null}
      {openReport && (
        <ReportDialog open={openReport} onClose={() => setOpenReport(false)} />
      )}
      {isAdmin && openExclusive && (
        <ExclusiveDialog
          isExclusive={isExclusive}
          open={openExclusive}
          onClose={() => setOpenExclusive(false)}
          handleSubmit={handleExclusive}
        />
      )}
    </Container>
  );
}
