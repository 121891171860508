import React from "react";

import { Container, Typography, Box, Divider,Grid,useMediaQuery } from "@mui/material";
import AboutImg from "assets/AboutUs.png"
import Breadcrumb from "../BreadCrumbs";
import { useTheme } from "@mui/material/styles";

const AboutUs: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Container maxWidth="md">
      
      <Box position="static" sx={{ padding: theme.spacing(isSmallScreen?2:5) }}></Box>
      <Breadcrumb />
      <Box my={4}>
        <Grid sx={{
           backgroundColor:"#b4f0ee",
           textAlign:"center",
           p:2,
          borderRadius:"10px",
          mb:4
           
        }}>
          <img src={AboutImg} width={50}/>
        <Typography variant="h1" gutterBottom 
        sx={{fontSize: isSmallScreen? "1.2rem":"2rem",
          textAlign:"center",
          color:"black"
        }}
        >
         About Us
        </Typography>
        </Grid>
        
       
        </Box>
    </Container>
  );
};

export default AboutUs;
