import { Typography } from "@mui/material";
import { COUNTRY_CURRENCY, currencySymbols } from "../utils/constants";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

interface CurrencyPriceProps {
  price: number;
  currency: string;
  onlyPrice?: boolean;
}

export const convertPrice = (
  price: number,
  currency: string,
  location: { country: string },
  rates: Record<string, number>
) => {
  const targetCurrency =
    COUNTRY_CURRENCY[location?.country as keyof typeof COUNTRY_CURRENCY] ||
    localStorage.getItem("currency") ||
    "USD";
  const productRate = rates[currency?.toLowerCase()] || 1;
  const targetRate = rates[targetCurrency?.toLowerCase()] || 1;

  return (price / productRate) * targetRate;
};

const CurrencyPrice: React.FC<CurrencyPriceProps> = ({
  price,
  currency,
  onlyPrice = false,
}) => {
  const { rates } = useSelector((state: RootState) => state.currency);
  const location = useSelector((state: RootState) => state.location.location);
  const targetCurrency =
    COUNTRY_CURRENCY[location?.country as keyof typeof COUNTRY_CURRENCY] ||
    localStorage.getItem("currency") ||
    "USD";
  const convertedPrice = useMemo(() => {
    return convertPrice(price, currency, location, rates);
  }, [rates, currency, location, price]);

  const formatPrice = (amount: number, currency: string) => {
    const symbol = currencySymbols[currency] || currency;
    return `${symbol} ${amount.toFixed(0)}`;
  };

  return (
    <Typography variant="inherit">
      {formatPrice(convertedPrice, targetCurrency)}
    </Typography>
  );
};

export default CurrencyPrice;
