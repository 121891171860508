import React from "react";

import { Container, Typography, Box, Divider,Grid,useMediaQuery } from "@mui/material";
import tncImg from "assets/tnc.png"
import Breadcrumb from "../BreadCrumbs";
import { useTheme } from "@mui/material/styles";

const TermsOfUse: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Container maxWidth="md">
      
      <Box position="static" sx={{ padding: theme.spacing(isSmallScreen?2:5) }}></Box>
      <Breadcrumb />
      <Box my={4}>
        <Grid sx={{
           backgroundColor:"#b4f0ee",
           textAlign:"center",
           p:2,
          borderRadius:"10px",
          mb:4
           
        }}>
          <img src={tncImg} width={50}/>
        <Typography variant="h1" gutterBottom 
        sx={{fontSize: isSmallScreen? "1.2rem":"2rem",
          textAlign:"center",
          color:"black"
        }}
        >
          Bidzbay Terms & Conditions
        </Typography>
        </Grid>
        
        <Typography variant="subtitle1" color="textSecondary" gutterBottom>
          Effective Date: 1 October 2024
        </Typography>
        <Divider />
        <Box mt={2}>
          <Typography variant="h6" component="h2">
            WHO WE ARE AND HOW TO CONTACT US
          </Typography>
          <Typography variant="body1" paragraph>
            The Platform is operated and owned by Gulf Land Commercial
            ("Bidzbay.com," "we," "our," "us"). Our mailing address is Floor 7,
            Building 241, Al Khuwair, Muscat, Oman. For inquiries regarding
            these Terms of Use, please email support@bidzbay.com.com.
          </Typography>

          <Typography variant="h6" component="h2">
            BY USING THE PLATFORM, YOU ACCEPT THESE TERMS OF USE
          </Typography>
          <Typography variant="body1" paragraph>
            By accessing and using the Platform, you confirm that you accept
            these Terms of Use and acknowledge that they govern your access to
            and use of the Platform and any Content.
          </Typography>

          <Typography variant="h6" component="h2">
            WE MAY MAKE CHANGES TO THESE TERMS OF USE
          </Typography>
          <Typography variant="body1" paragraph>
            We may amend these Terms of Use at any time, with or without notice.
            The updated Terms will be posted on this page.
          </Typography>

          <Typography variant="h6" component="h2">
            YOUR RIGHT TO USE THE PLATFORM AND CONTENT
          </Typography>
          <Typography variant="body1" paragraph>
            Subject to your compliance with these Terms of Use, Bidzbay.com
            grants you a personal, limited, non-transferable, non-exclusive,
            revocable right to use the Platform and Content.
          </Typography>

          <Typography variant="h6" component="h2">
            RESTRICTIONS
          </Typography>
          <Typography variant="body1" paragraph>
            You must not: Use the Platform unlawfully or for any illegal
            purpose, attempt unauthorized access to the Platform, introduce
            malicious software, impersonate any person, etc.
          </Typography>

          {/* Repeat for all sections */}

          <Typography variant="h6" component="h2">
            ACCOUNT CREATION
          </Typography>
          <Typography variant="body1" paragraph>
            To access certain features of the Platform, you must create an
            account. You are liable for all activities that occur under your
            Account.
          </Typography>

          {/* Add more sections similarly */}
        </Box>
        </Box>
    </Container>
  );
};

export default TermsOfUse;
