import React, { useEffect, useState } from "react";
import { UserProfile } from "./types";
import {
  Tabs,
  Tab,
  Box,
  Typography,
  Grid,
  TextField,
  FormControl,
  Switch,
  FormControlLabel,
  Button,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  InputAdornment,
} from "@mui/material";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  useLocation,
} from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import AutoCompleteSearchBox from "../common/AutoCompleteSearchBox";
import { ProductType } from "../products/product.type";
import { IOptions } from "../common/AutoCompleteSearchBox";
import { useSelector } from "react-redux";
import {
  getUserDetails,
  getUsers,
  updateUserDetails,
} from "../../store/slices/auth.slice";
import { AppDispatch, RootState } from "../../store/store";
import { useDispatch } from "react-redux";
import {
  changeStatusProductById,
  filterLocation,
  fetchProductDataForDownload,
} from "../services/product.service";
import { countryCodes } from "../utils/country-code.constant";
import UserAddedProductItems from "./LoggedInUserComponents/UserAddedProductItems";
import DataTable from "../DataTable";
import GeosuggestDropdown from "../GeosuggestDropdown";
import { setApiResponse } from "../../store/slices/product.slice";
import Loader from "../Loader";
import { removeNullAndEmpty } from "../utils/helpers";
import AssessmentIcon from "@mui/icons-material/Assessment";
const Profile: React.FC<{}> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    users,
    userDetails,
    currentUserDetails,
    user: { email },
    loading,
  } = useSelector((state: RootState) => state.auth);
  const [selectedUser, setSelectedUser] = useState<IOptions | null>({
    label: currentUserDetails.user.email,
    value: currentUserDetails.user.email,
  });

  const [userList, setSelectedUserList] = useState<IOptions[]>([
    { label: "", value: "" },
  ]);

  const [productList, setProductList] = useState<ProductType[]>([]);
  const [downloadProductList, setDownloadProductList] = useState<ProductType[]>(
    []
  );
  const isAdmin = currentUserDetails.user.role === "ADMIN";

  useEffect(() => {
    dispatch(getUserDetails(""));
    dispatch(setApiResponse({ success: false }));
  }, [dispatch]);

  useEffect(() => {
    if (isAdmin && users && users.length > 0) {
      getProductDownloadData();
    }
  }, [isAdmin, users]);

  useEffect(() => {
    if (users.length === 0 && isAdmin) {
      const query = "?fields=email,first_name,last_name,uuid,phone_number";
      dispatch(getUsers(query));
    }
  }, [dispatch, users, isAdmin]);

  useEffect(() => {
    if (users.length > 0 && isAdmin) {
      let selectedUserEmail = (selectedUser as IOptions)?.value;
      selectedUserEmail = selectedUserEmail ? selectedUserEmail : email;
      dispatch(getUserDetails(selectedUserEmail));
    }
  }, [dispatch, email, selectedUser, users, isAdmin]);

  useEffect(() => {
    if (users.length > 0) {
      const usersList = users.map(
        (user: { first_name: string; last_name: string; email: string }) => ({
          label:
            `${user.first_name ? user.first_name + " " : ""}${
              user.last_name ? user.last_name : ""
            }`.trim() || user.email,
          value: user.email,
        })
      );
      setSelectedUserList(usersList);
    }
  }, [users]);

  const handleUserChange = (value: IOptions | IOptions[] | null) => {
    setSelectedUser(value as IOptions);
  };

  const fetchUserData = (userId: string) => {
    return users.find((user: any) => user.uuid === userId);
  };

  const mapSellerBuyerInfo = (products: any[]) => {
    if (products.length > 0) {
      return products.map((product: any) => {
        const seller: any = fetchUserData(product.seller.id);
        product.seller = {
          ...product.seller,
          email: seller?.email,
          phone_number: seller?.phone_number,
        };
        const buyer: any = fetchUserData(product.buyer.userId);
        product.buyer = {
          ...product.buyer,
          email: buyer?.email,
          phone_number: product.buyer.phone_number || buyer?.phone_number,
        };
        return product;
      });
    }
    return products;
  };

  async function getProductDownloadData() {
    const resp = await fetchProductDataForDownload({});
    const product = mapSellerBuyerInfo(resp?.products || []);
    setDownloadProductList(product || []);
  }

  const location = useLocation();
  const currentTab = location.pathname.split("/")[2] || "profile";

  const handleDeleteProduct = async (productId: string, action: string) => {
    const response = await changeStatusProductById(productId, action);
    if (response) {
      const products = productList.filter(
        (product: ProductType) => product.uuid !== productId
      );

      setProductList(products);
    }
  };
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const filterPurchasedProduct = () => {
    if (productList && productList.length > 0) {
      return productList.filter(
        (product: ProductType) =>
          product.state === "SOLD" &&
          product?.buyer?.userId === userDetails.user.uuid
      );
    }

    return [];
  };

  const filterListedProduct = () => {
    if (productList && productList.length > 0) {
      return (
        productList.filter(
          (product: ProductType) =>
            product.state !== "SOLD" && product.state !== "ARCHIEVED"
        ) || []
      );
    }
    return [];
  };

  const filterSoldProduct = () => {
    if (productList && productList.length > 0) {
      return (
        productList.filter(
          (product: ProductType) =>
            product.state === "SOLD" &&
            product?.seller?.id === userDetails.user.uuid
        ) || []
      );
    }
    return [];
  };

  useEffect(() => {
    if (userDetails?.products) {
      setProductList(userDetails?.products);
    }
  }, [userDetails]);

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ p: 1, pt: 5 }}>
      {loading && <Loader />}
      <Grid display={"flex"}>
        {isAdmin && (
          <FormControl margin="normal">
            <AutoCompleteSearchBox
              options={userList}
              multiple={false}
              limitTags={1}
              label="Search User"
              value={selectedUser}
              handleChange={handleUserChange}
              fullWidth={true}
            />
          </FormControl>
        )}
        {isAdmin && (
          <Button
            variant="contained"
            startIcon={<AssessmentIcon />}
            color="primary"
            component={Link}
            to="download"
            sx={{
              position: "absolute",
              right: isSmallScreen ? "20px" : "90px",
              top: isSmallScreen ? "60px" : "140px",
            }}
          >
            View Report
          </Button>
        )}
      </Grid>
      {!loading && userDetails && (
        <>
          <Tabs
            value={currentTab}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="scrollable force tabs example"
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab
              label="Profile"
              value="profile"
              component={Link}
              to="profile"
            />
            {/* {isAdmin && (
              <Tab
                label="Pending Approval"
                value="approveProducts"
                component={Link}
                to="approveProducts"
              />
            )} */}
            {userDetails?.products && userDetails?.products.length > 0 && (
              <Tab
                label="Listed Items"
                value="listedItems"
                component={Link}
                to="listedItems"
              />
            )}
            {userDetails?.products && userDetails?.products.length > 0 && (
              <Tab
                label="Purchased Items"
                value="purchased"
                component={Link}
                to="purchased"
              />
            )}
            {userDetails?.products && userDetails?.products.length > 0 && (
              <Tab label="Sold Items" value="sold" component={Link} to="sold" />
            )}
            {/* {isAdmin && (
             
             <Tab
               label="Sold Details"
               value="download"
               component={Link}
               to="download"
             />
           )} */}
          </Tabs>
          <Routes>
            <Route
              path="profile"
              element={
                <ProfileDetails
                  user={userDetails}
                  currentUserDetails={currentUserDetails}
                />
              }
            />
            <Route
              path="listedItems"
              element={
                <UserAddedProductItems
                  category="Listed"
                  label={
                    isAdmin &&
                    currentUserDetails.user.email !== userDetails.user.email
                      ? `Listed Details of ${userDetails.user.email}`
                      : `Listed`
                  }
                  items={filterListedProduct() || []}
                  currentUserDetails={currentUserDetails}
                  handleDeleteProduct={handleDeleteProduct}
                />
              }
            />
            <Route
              path="purchased"
              element={
                <UserAddedProductItems
                  category="Purchased"
                  label={
                    isAdmin &&
                    currentUserDetails.user.email !== userDetails.user.email
                      ? `Purchased Details of ${userDetails.user.email}`
                      : `Purchased`
                  }
                  items={filterPurchasedProduct() || []}
                  currentUserDetails={currentUserDetails}
                  handleDeleteProduct={() => {}}
                />
              }
            />
            <Route
              path="sold"
              element={
                <UserAddedProductItems
                  category="Sold"
                  label={
                    isAdmin &&
                    currentUserDetails.user.email !== userDetails.user.email
                      ? `Sold Details of ${userDetails.user.email}`
                      : `Sold`
                  }
                  items={filterSoldProduct() || []}
                  currentUserDetails={currentUserDetails}
                  handleDeleteProduct={() => {}}
                />
              }
            />
            <Route
              path="download"
              element={
                <DataTable
                  data={downloadProductList}
                  columns={[
                    { Header: "Title", accessor: "title" },
                    { Header: "Description", accessor: "description" },
                    { Header: "Price", accessor: "price" },
                    { Header: "Sold", accessor: "buyer.amount" },
                    { Header: "City", accessor: "location.city" },
                    { Header: "State", accessor: "location.state" },
                    { Header: "Country", accessor: "location.country" },
                    { Header: "Seller", accessor: "seller.email" },
                    // { Header: "Seller No.", accessor: "seller.phone_number" },
                    { Header: "Buyer", accessor: "buyer.email" },
                    // { Header: "Buyer No.", accessor: "buyer.phone_number" },
                  ]}
                  // currentUserDetails={currentUserDetails}
                />
              }
            />
            {/* {isAdmin && !isLoading && (
              <Route
                path="approveProducts"
                element={
                  <UserAddedProductItems
                    label={
                      isAdmin &&
                      currentUserDetails.user.email !== userDetails.user.email
                        ? `Approve Products of ${userDetails.user.email}`
                        : "Approve Products"
                    }
                    category={`approveProducts`}
                    items={data?.products || []}
                    currentUserDetails={currentUserDetails}
                    handleDeleteProduct={() => {}}
                  />
                }
              />
            )} */}
          </Routes>
        </>
      )}
    </Box>
  );
};

const ProfileDetails: React.FC<{
  user: { user: UserProfile; products: ProductType[] };
  currentUserDetails: { user: UserProfile; products: ProductType[] };
}> = ({ user, currentUserDetails }) => {
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    secondary_number: "",
    secondary_email: "",
    verification_status: false,
    heard_from: "",
    remark: {
      remark: "",
      remarked_by: "",
    },
    location: {
      city: "",
      state: "",
      country: "",
    },
    countryCode: "",
    address: "",
  });

  const validateForm = () => {
    let valid = true;
    let errors = {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      location: {
        city: "",
        state: "",
        country: "",
      },
      countryCode: "",
      address: "",
      secondary_number: "",
      secondary_email: "",
      verification_status: false,
      heard_from: "",
      remark: {
        remark: "",
        remarked_by: "",
      },
    };

    if (!editableUser.first_name) {
      errors.firstName = "First Name is required";
      valid = false;
    }

    if (!editableUser.last_name) {
      errors.lastName = "Last Name is required";
      valid = false;
    }

    if (!editableUser.phone_number) {
      errors.phoneNumber = "Phone Number is required";
      valid = false;
    }

    if (!editableUser.location?.country) {
      errors.location.country = "Location is required";
      valid = false;
    }

    if (!editableUser.country_code) {
      errors.countryCode = "Country Code is required";
      valid = false;
    }

    if (!editableUser.address) {
      errors.address = "Address is required";
      valid = false;
    }

    setErrors(errors);
    return valid;
  };

  const mainLocation = useSelector(
    (state: RootState) => state.location.location
  );
  const isAdmin = currentUserDetails.user.role === "ADMIN";
  const dispatch = useDispatch<AppDispatch>();
  const getCurrentCountry = (
    location: { city?: string; state?: string; country: any } | undefined
  ) => {
    if (location && location.country) {
      return (
        countryCodes.find(
          ({ label }) => location.country.toLowerCase() === label.toLowerCase()
        )?.value || ""
      );
    }
    return "";
  };
  const [editableUser, setEditableUser] = useState<Partial<UserProfile>>({
    first_name: user.user.first_name || "",
    last_name: user.user.last_name || "",
    code: user.user.code || "",
    country_code:
      user.user.country_code ||
      user.user?.phone_number?.slice(0, -10) ||
      getCurrentCountry(mainLocation),
    phone_number: user.user?.phone_number?.slice(-10) || "",
    secondary_number: user.user?.secondary_number?.slice(-10) || "",
    secondary_email: user.user?.secondary_email
      ? user.user?.secondary_email
      : null,
    verification_status: user.user.verification_status || false,
    heard_from: user.user.heard_from || "",
    remark: user.user.remark || { remark: "", remarked_by: "" },
    //created_at: user.user.created_at || "",
    location:
      user.user.location ||
      (mainLocation.city &&
      mainLocation.state &&
      mainLocation.country &&
      currentUserDetails.user.email === user.user.email
        ? mainLocation
        : { country: "", state: "", city: "" }),
    address: user.user?.address || "",
    is_active: user.user.is_active || false,
    is_exclusive: user.user.is_exclusive || false,
    email: user.user.email || "",
    role: user.user.role || "USER",
  });

  const handleUserChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setEditableUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleRemarkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setEditableUser((prevState) => ({
      ...prevState,
      remark: { remark: value },
    }));
  };

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;

    setEditableUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    console.log(name, checked);
    setEditableUser((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleSave = () => {
    if (validateForm()) {
      //@ts-ignore
      const updatedUser: UserProfile = { ...removeNullAndEmpty(editableUser) };
      dispatch(updateUserDetails(updatedUser));
    }
  };

  const getCurrentCountryCode = (code: string = "") => {
    return countryCodes.find(
      ({ value }) => code.toLowerCase() === value.toLowerCase()
    );
  };

  const handleCountryCodeChange = (value: IOptions | IOptions[] | null) => {
    if (value && !Array.isArray(value)) {
      const currentCountryCode = getCurrentCountryCode(value.value);

      setEditableUser((prevState) => {
        return {
          ...prevState,
          country_code: currentCountryCode?.value,
        };
      });
    } else {
      setEditableUser((prevState) => {
        return {
          ...prevState,
          country_code: "",
        };
      });
    }
  };

  const setLocation = (location: {
    city: "" | string;
    state: "" | string;
    country: "" | string;
  }) => {
    setEditableUser({
      ...editableUser,
      location: {
        city: location.city || editableUser.location?.city || "",
        state: location.state || editableUser.location?.state || "",
        country: location.country || editableUser.location?.country || "",
      },
      country_code: getCurrentCountry(location),
    });
  };

  return (
    <Box sx={{ p: { xs: 2, sm: 3, md: 5 } }}>
      <Typography variant="h5" mb={3}>
        {isAdmin && currentUserDetails.user.email !== user.user.email
          ? `Profile Details of ${user.user.email}`
          : `Profile Details`}
      </Typography>
      <Grid container spacing={4}>
        {/* First Row */}
        <Grid item xs={12} sm={4} md={3}>
          <TextField
            fullWidth
            label="Code"
            name="code"
            disabled
            value={editableUser.code || ""}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TextField
            fullWidth
            label="First Name"
            name="first_name"
            required
            value={editableUser.first_name || ""}
            onChange={handleUserChange}
            error={!!errors.firstName}
            helperText={errors.firstName}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TextField
            fullWidth
            label="Last Name"
            name="last_name"
            required
            value={editableUser.last_name || ""}
            onChange={handleUserChange}
            error={!!errors.lastName}
            helperText={errors.lastName}
          />
        </Grid>

        {/* Second Row */}
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12} sm={4} md={3}>
            <AutoCompleteSearchBox
              options={countryCodes}
              multiple={false}
              limitTags={1}
              required
              label="Search Country"
              value={getCurrentCountryCode(editableUser?.country_code)}
              handleChange={handleCountryCodeChange}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <TextField
              fullWidth
              type="tel"
              label="Phone Number"
              name="phone_number"
              required
              value={editableUser.phone_number || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.value.length <= 10) {
                  handleUserChange(e);
                }
              }}
              error={!!errors.phoneNumber}
              helperText={errors.phoneNumber}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {editableUser.country_code}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <TextField
              fullWidth
              type="tel"
              label="Secondary Number"
              name="secondary_number"
              value={editableUser.secondary_number || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.value.length <= 10) {
                  handleUserChange(e);
                }
              }}
              error={!!errors.secondary_number}
              helperText={errors.secondary_number}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {editableUser.country_code}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        {/* Third Row */}
        <Grid item xs={12} sm={4} md={3}>
          <GeosuggestDropdown
            showInput
            setShowInput={() => {}}
            spacing={true}
            hideDelete={true}
            value={filterLocation(editableUser.location)}
            onChange={setLocation}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
          <TextField
            fullWidth
            label="Email"
            name="email"
            required
            value={user.user.email}
            InputProps={{
              readOnly: true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TextField
            fullWidth
            label="Secondary Email"
            name="secondary_email"
            value={user.user.secondary_email}
            onChange={handleUserChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            fullWidth
            label="Address"
            name="address"
            multiline
            required
            value={editableUser?.address || ""}
            onChange={handleUserChange}
            error={!!errors?.address}
            helperText={errors?.address}
          />
        </Grid>
        {(!user?.user?.heard_from || isAdmin) && (
          <Grid item xs={12} sm={4} md={3}>
            <FormControl fullWidth variant="outlined">
              <InputLabel sx={{backgroundColor:"white"}} id="heard-label">
                Where did you hear about us?
              </InputLabel>
              <Select
                labelId="heard-label"
                id="heard"
                name="heard_from"
                value={editableUser?.heard_from}
                onChange={(e: SelectChangeEvent<string>) =>
                  handleSelectChange(e)
                }
              >
                <MenuItem value="">
                  <em>Select a source</em>
                </MenuItem>
                <MenuItem value="social_media">Social Media</MenuItem>
                <MenuItem value="friend">Friend</MenuItem>
                <MenuItem value="advertisement">Advertisement</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}

        {/* Fourth Row for Admin */}
        {isAdmin && (
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                fullWidth
                label="Remark"
                name="remark"
                multiline
                required
                value={editableUser?.remark?.remark || ""}
                onChange={handleRemarkChange}
                error={!!errors?.remark?.remark}
                helperText={errors?.remark?.remark}
              />
            </Grid>
            <Grid item xs={12} sm={2} md={2}>
              <FormControl fullWidth>
                <InputLabel id="role-label">Role</InputLabel>
                <Select
                  labelId="role-label"
                  id="role"
                  name="role"
                  value={editableUser.role}
                  label="Role"
                  onChange={(e: SelectChangeEvent<string>) =>
                    handleSelectChange(e)
                  }
                >
                  <MenuItem value="ADMIN">Admin</MenuItem>
                  <MenuItem value="USER">User</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4} md={2}>
              <FormControlLabel
                control={
                  <Switch
                    checked={editableUser.verification_status}
                    name="verification_status"
                    onChange={handleSwitchChange}
                  />
                }
                label="Verified Member"
              />
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <FormControlLabel
                name="is_active"
                control={
                  <Switch
                    name="is_active"
                    checked={editableUser.is_active}
                    onChange={handleSwitchChange}
                  />
                }
                label="Active Status"
              />
            </Grid>
          </Grid>
        )}
      </Grid>

      <Box mt={3}>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Save Changes
        </Button>
      </Box>
    </Box>
  );
};

export default Profile;
