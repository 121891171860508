import { useMutation, useQuery, useQueryClient } from "react-query";
import fetchWrapper, {
  RequestOptions,
} from "../../components/utils/fetchWrapper";

// export const bidService = {
//   placeBid,
//   getAllBids,
// };

const getAllBids = async (productId: string) => {
  return fetchWrapper(`bids/product/${productId}`);
};

async function placeBid(
  productId: string,
  amount: number,
  currency: string,
  consent: boolean = false,
  phone_number: string
) {
  const options: RequestOptions = {
    method: "POST",
    body: { productId, amount, currency, consent, phone_number },
  };

  return await fetchWrapper("bids", options);
}

async function updateBid(
  bidId: string,
  amount: number,
  currency: string,
  consent: boolean = false,
  phone_number: string
) {
  const options: RequestOptions = {
    method: "PATCH",
    body: { amount, currency, consent, phone_number },
  };

  return await fetchWrapper(`bids/${bidId}`, options);
}

async function acceptBid(bidId: string, productId: string, consent: boolean) {
  const options: RequestOptions = {
    method: "PATCH",
    body: { productId, isAccepted: true, consent },
  };

  return await fetchWrapper(`bids/accept/${bidId}`, options);
}

async function deleteBid(bidId: string) {
  const options: RequestOptions = {
    method: "DELETE",
  };

  return await fetchWrapper(`bids/${bidId}`, options);
}

export const useFetchBids = (productId: string) => {
  return useQuery<any, "bids">(
    ["bids", productId],
    () => getAllBids(productId),
    {
      staleTime: 50 * 60 * 1000, // Cache for 5 minutes
    }
  );
};

// Place bid mutation
export const usePlaceBid = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (bidData: {
      productId: string;
      amount: number;
      currency: string;
      consent: boolean;
      phone_number: string;
    }) =>
      placeBid(
        bidData.productId,
        bidData.amount,
        bidData.currency,
        bidData.consent,
        bidData.phone_number
      ),
    {
      onSuccess: () => {
        // Invalidate and refetch bids after placing a bid
        queryClient.invalidateQueries("bids");
      },
      onError: (error) => {
        console.error("Failed to place bid:", error);
      },
    }
  );
};

// Update bid mutation
export const useUpdateBid = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (bidData: {
      bidId: string;
      amount: number;
      currency: string;
      consent: boolean;
      phone_number: string;
    }) =>
      updateBid(
        bidData.bidId,
        bidData.amount,
        bidData.currency,
        bidData.consent,
        bidData.phone_number
      ),
    {
      onSuccess: () => {
        // Invalidate and refetch bids after placing a bid
        queryClient.invalidateQueries("bids");
      },
      onError: (error) => {
        console.error("Failed to update bid:", error);
      },
    }
  );
};

// Accept bid mutation
export const useAcceptBid = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (bidData: { bidId: string; productId: string; consent: boolean }) =>
      acceptBid(bidData.bidId, bidData.productId, bidData.consent),
    {
      onSuccess: () => {
        // Invalidate and refetch bids after placing a bid
        queryClient.invalidateQueries("bids");
        queryClient.invalidateQueries("products");
      },
      onError: (error) => {
        console.error("Failed to accept bid:", error);
      },
    }
  );
};

// Accept bid mutation
export const useDeleteBid = () => {
  const queryClient = useQueryClient();

  return useMutation((bidData: { bidId: string }) => deleteBid(bidData.bidId), {
    onSuccess: () => {
      // Invalidate and refetch bids after placing a bid
      queryClient.invalidateQueries("bids");
    },
    onError: (error) => {
      console.error("Failed to accept bid:", error);
    },
  });
};
