import React, { useState, useEffect, useMemo } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  Chip,
  Grid,
  Button,
  CardActions,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { ProductType } from "./product.type";
import moment from "moment";
import { useNavigate, createSearchParams } from "react-router-dom";
import TruncatedText from "../Truncated.wrapper";
import { orange } from "@mui/material/colors";
import noImage from "../../assets/Noimage.jpg";
import CurrencyPrice from "../common/PriceWithCurrency";
import { filterLocation } from "../services/product.service";
import { getMediaType } from "../utils/helpers";

interface ProductProps {
  product: ProductType;
  onAddToCart?: () => void;
  approveProduct?: (e: any) => void;
  rejectProduct?: (e: any) => void;
  deleteProduct?: (e: any) => void;
  showButtons?: boolean;
  showDelete?: boolean;
  showEdit?: boolean;
  showApproveButton?: boolean;
}

const Product: React.FC<ProductProps> = ({
  product,
  onAddToCart,
  approveProduct,
  rejectProduct,
  deleteProduct,
  showButtons = true,
  showDelete = false,
  showEdit = false,
  showApproveButton = false,
}) => {
  const [difference, setDifference] = useState("");
  const navigate = useNavigate();
  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up("md"));
  useEffect(() => {
    const postedDate = moment(product?.postedAt);
    const now = moment();
    const daysDifference = now.diff(postedDate, "days");

    let diffString = "";
    if (daysDifference === 0) {
      diffString = "Today";
    } else if (daysDifference === 1) {
      diffString = "Yesterday";
    } else if (daysDifference < 30) {
      diffString = `${daysDifference} days ago`;
    } else {
      const monthsDifference = now.diff(postedDate, "months");
      diffString = `${monthsDifference} month${
        monthsDifference > 1 ? "s" : ""
      } ago`;
    }

    setDifference(diffString);
  }, [product?.postedAt]);

  const handleEdit = () => {
    navigate({
      pathname: "/add-product",
      search: `?${createSearchParams({
        action: "edit",
        uuid: product.uuid,
      })}`,
    });
    console.log(`Edit product with id: ${product.uuid}`);
  };

  const mediaType = useMemo(
    () => getMediaType(product?.images[0]),
    [product?.images]
  );

  return (
    <Card
      sx={{
        maxWidth: 345,
        borderRadius: 2,
        boxShadow: 3,

        minHeight:
          showEdit || showDelete || showApproveButton
            ? "10vh"
            : isBigScreen
            ? "55vh"
            : "30vh",
     
      
        justifyContent: "space-between",
      }}
      onClick={() => {
        navigate(`/products/${product.uuid}`);
      }}
    >
      <Box sx={{ position: "relative" }}>
        {mediaType === "video" && (
          <CardMedia
            component="video"
            height="200"
            sx={{ objectFit: "cover" }}
            autoPlay
            muted
            loop
          >
            <source
              src={product?.images?.[0] ?? product.images[0]}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </CardMedia>
        )}
        {mediaType === "image" && (
          <CardMedia
            component="img"
            height="200"
            src={product?.images?.[0] ? product.images[0] : noImage}
            alt="Product image"
          />
        )}
        {product?.status && (
          <Chip
            label={product.status}
            color="primary"
            sx={{ position: "absolute", top: 10, left: 10 }}
          />
        )}
        {product?.highestBid?.amount &&
          product?.bidEndDate &&
          product.state !== "SOLD" && (
            <Box
              sx={{
                position: "absolute",
                top: 10,
                right: 0,
                paddingRight: "7px",
                display: "flex",
                alignItems: "center",
                backgroundColor: orange[800],
                color: "white",
                borderRadius: "5px 0px 0px 5px",
              }}
            >
              <Chip
                label="Bid:"
                color="secondary"
                sx={{
                  backgroundColor: orange[800],
                  color: "white",

                  fontSize: "16px",
                }}
              />
              <CurrencyPrice
                price={product?.highestBid?.amount}
                currency={product?.highestBid?.currency || "USD"}
              />
            </Box>
          )}
        {product?.state === "SOLD" && (
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%) rotate(-45deg)", // Center and rotate
              backgroundColor: "rgba(92, 234, 210, 1)", // Red semi-transparent background
              color: "white",
              fontSize: "24px",
              fontWeight: "bold",
              padding: "10px 30px",
              borderRadius: "5px",
              zIndex: 2, // Ensure it's on top of the image
              textAlign: "center",
            }}
          >
            SOLD
          </Box>
        )}
      </Box>
      <CardContent
        sx={{
          flex: "1 0 auto",
          borderTop:
            product?.highestBid?.amount &&
            product?.bidEndDate &&
            product?.state !== "SOLD"
              ? `5px solid ${orange[800]}`
              : product?.state === "SOLD"
              ? `5px solid rgba(92, 234, 210, 1)`
              : "",
        }}
      >
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          title={product?.title}
        >
          {product?.title && (
            <TruncatedText text={product?.title} maxLength={20} />
          )}
        </Typography>
        <Typography variant="h5" color="textPrimary">
          <CurrencyPrice
            price={product?.price}
            currency={product?.currency || "USD"}
          />
          <Typography color={"black"}> {product?.subcategory?.name}</Typography>
        </Typography>
        <Typography
          variant="body2"
          color="textPrimary"
          sx={{ mb: 2, height: isBigScreen ? "10vh" : "7vh" }}
          title={product?.description}
        >
          {product?.description && (
            <TruncatedText text={product?.description} />
          )}
        </Typography>
        <Box sx={{ mt: "auto" }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <LocationOnIcon fontSize="small" sx={{ mr: 0.5 }} />
                <TruncatedText
                  text={filterLocation(product?.location)}
                  maxLength={28}
                />
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" color="textPrimary" sx={{margin:0}}>
                {difference}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
      {(showEdit || showDelete || showApproveButton) && (
        <CardActions sx={{ pb: 1, borderTop: "1px solid #eee" }}>
          {showApproveButton && (
            <>
              <Grid item xs={4}>
                <Button
                  color="primary"
                  fullWidth
                  onClick={approveProduct}
                  sx={{ borderRadius: "0 0 0 4px" }}
                >
                  Approve
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  color="primary"
                  fullWidth
                  onClick={rejectProduct}
                  sx={{ borderRadius: "0 0 0 4px" }}
                >
                  Reject
                </Button>
              </Grid>
            </>
          )}
          {showEdit && (
            <Grid item xs={6}>
              <Button
                color="primary"
                fullWidth
                onClick={(e) => {
                  e.stopPropagation();
                  handleEdit();
                }}
                sx={{ borderRadius: "0 0 0 4px" }}
              >
                Edit
              </Button>
            </Grid>
          )}
          {showDelete && (
            <Grid item xs={6}>
              <Button
                color="primary"
                fullWidth
                onClick={deleteProduct}
                sx={{ borderRadius: "0 0 4px 0" }}
              >
                Delete
              </Button>
            </Grid>
          )}
        </CardActions>
      )}
    </Card>
  );
};

export default Product;
