import React from "react";
import { Box, Grid, IconButton, Link, Typography,  useMediaQuery, } from "@mui/material";
import { Facebook, Twitter, Instagram, LinkedIn } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import logo from "../assets/BIDZBAYFooter.png";
import linkedin from "../assets/linkedin.png";
import instagram from "../assets/instagram.png";
import yt from "../assets/ytlogo.jpg";
import twitter from "../assets/twitterx.jpg";
import facebook from "../assets/facebook.png";
import { useNavigate } from "react-router-dom";

const Footer: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isBigScreen = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        mt: "auto",
        backgroundColor: theme.palette.primary.main,
        color: "black",
        textAlign: "center",
      }}
    >
      {/* {!isSmallScreen && <img
              src={logo}
              alt="BidzBay Logo"
              style={{   height: isSmallScreen ? "0vh":  isBigScreen ? "11vh":"0vh",
                position:"absolute",
                cursor:"pointer",
                left:"15vw",marginTop:"-20px" }}
              onClick={() => navigate("/")}
            />} */}


       {!isSmallScreen ? (
      <Grid display={"flex"} justifyContent={"center"} >
        <Link
          href="/about-us"
          rel="noopener"
          color="secondary.main"
          underline="none"
          marginRight={3}
          sx={{
            '&:hover': {
              color: 'orange', 
              textDecoration: 'underline', 
            },
          }}
        >
          About Us
        </Link>
        

        <Link
          href="/Products"       
          rel="noopener"
          color="secondary.main"
          underline="none"
          marginRight={3}
          sx={{
            '&:hover': {
              color: 'orange', 
              textDecoration: 'underline', 
            },
          }}
        >
         Product
        </Link>

        <Link
          href="/contact-us"
          rel="noopener"
          color="secondary.main"
          underline="none"
          marginRight={3}
          sx={{
            '&:hover': {
              color: 'orange', 
              textDecoration: 'underline', 
            },
          }}
        >
         Contact Us 
        </Link>

        <Link
          href="/privacy-policy"
          rel="noopener"
          color="secondary.main"
          underline="none"
          marginRight={3}
          sx={{
            '&:hover': {
              color: 'orange', 
              textDecoration: 'underline', 
            },
          }}
        >
         Privacy Policy
        </Link>
      
        <Link
          href="/terms-conditions"
          rel="noopener"
          color="secondary.main"
          underline="none"
          marginRight={3}
          sx={{
            '&:hover': {
              color: 'orange', 
              textDecoration: 'underline', 
            },
          }}
        >
         Terms & Conditions
        </Link>
        
      </Grid>) :

     ( <Grid xs={12} >
      <Grid item xs={6} >
      <Link
          href="/about-us"
          rel="noopener"
          color="secondary.main"
          underline="none"
          marginRight={3}
          sx={{
            '&:hover': {
              color: 'orange', 
              textDecoration: 'underline', 
            },
          }}
        >
          About Us
        </Link>
        

        <Link
          href="/Products"       
          rel="noopener"
          color="secondary.main"
          underline="none"
          marginRight={3}
          sx={{
            '&:hover': {
              color: 'orange', 
              textDecoration: 'underline', 
            },
          }}
        >
         Products
        </Link>

        <Link
          href="/contact-us"
          rel="noopener"
          color="secondary.main"
          underline="none"
          marginRight={3}
          sx={{
            '&:hover': {
              color: 'orange', 
              textDecoration: 'underline', 
            },
          }}
        >
         Contact Us 
        </Link>
      </Grid>
      <Grid item xs={6} >
      <Link
          href="/privacy-policy"
          rel="noopener"
          color="secondary.main"
          underline="none"
          marginRight={3}
          sx={{
            '&:hover': {
              color: 'orange', 
              textDecoration: 'underline', 
            },
          }}
        >
         Privacy Policy
        </Link>
      
        <Link
          href="/terms-conditions"
          rel="noopener"
          color="secondary.main"
          underline="none"
          marginRight={3}
          sx={{
            '&:hover': {
              color: 'orange', 
              textDecoration: 'underline', 
            },
          }}
        >
         Terms & Conditions
        </Link>
      </Grid>

      </Grid>)}
      <Box sx={{ mt: 3, }}>
        {/* <Link
          href="https://www.facebook.com"
          target="_blank"
          rel="noopener"
          color="inherit"
        >
          <IconButton sx={{ color: theme.palette.text.primary }}>
            <Facebook />
          </IconButton>
        </Link>
        <Link
          href="https://www.twitter.com"
          target="_blank"
          rel="noopener"
          color="inherit"
        >
          <IconButton sx={{ color: theme.palette.text.primary }}>
            <Twitter />
          </IconButton>
        </Link> */}
        <Link
          href="https://www.instagram.com/p/C9ouGhmpBLj/?igsh=MWZ2OHIxMjF4cXIzOQ=="
          target="_blank"
          rel="noopener"
          color="inherit"
           marginRight="3px"
        >
          <img
              src={instagram}
              alt="instagram"
              style={{   height: isSmallScreen ? "3.5vh":isBigScreen? "3.5vh":"3vh",
                cursor:"pointer",}}
            />
       
        </Link>
        <Link
          href="https://linkedin.com/company/bidz-bay"
          target="_blank"
          rel="noopener"
          color="inherit"
           marginRight="3px"
         
        >
          <img
              src={linkedin}
              alt="linkedin"
              style={{   height: isSmallScreen ? "3.5vh":isBigScreen? "3.5vh":"3vh",
                cursor:"pointer",}}
            />
        </Link>

        <Link
          href="https://x.com/bidzbay325597"
          target="_blank"
          rel="noopener"
          color="inherit"
           marginRight="3px"
         
        >
          <img
              src={twitter}
              alt="linkedin"
              style={{   height: isSmallScreen ? "3.5vh":isBigScreen? "3.5vh":"3vh",
                cursor:"pointer",}}
            />
        </Link>

        <Link
          href="#"
          target="_blank"
          rel="noopener"
          color="inherit"
           marginRight="3px"
         
        >
          <img
              src={facebook}
              alt="linkedin"
              style={{   height: isSmallScreen ? "3.5vh":isBigScreen? "3.5vh":"3vh",
                cursor:"pointer",}}
            />
        </Link>
        <Link
          href="https://www.youtube.com/@BidzBay-s3c"
          target="_blank"
          rel="noopener"
          color="inherit"
           marginRight="3px"
         
        >
          <img
              src={yt}
              alt="linkedin"
              style={{   height: isSmallScreen ? "3.5vh":isBigScreen? "3.5vh":"3vh",
                cursor:"pointer",}}
            />
        </Link>
      </Box>
      <Typography variant="body1" 
      sx={{color:"secondary.main"}}
      >
        © {new Date().getFullYear()} Bidz Bay. All rights reserved.
      </Typography>
    </Box>
  );
};

export default Footer;
